<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 50em;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
    justify-content: center;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #007fbe;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    bottom: 0;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn {
    color: white;
    background: #007fbe;
    border: 1px solid #007fbe;
    border-radius: 2px;
    padding: 10px;
    width: 10em;
  }
</style>

<template>
      <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
            <h2>{{ reportInput.title }}</h2>
        </slot>
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
        <v-icon color="black">mdi-close</v-icon>
        </button>
      </header>

      <section class="modal-body">
        <slot name="body" >
            <div v-html="reportInput.contents"></div>
        </slot>
       </section>

      <footer class="modal-footer">
        <button
          type="button"
          class="btn"
          @click="close"
        >
          Close
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportInfoModal',
  props: ['reportInput'],
  data: () => ({
    showVideo: false
  }),
  methods: {
    close() {
        this.$emit('close');
    }
  }
}
</script>