<template>
  <v-card :disabled="disabled">
    <v-card-title class="accent--text header-font">Upload Reports</v-card-title>
    <v-card-text>
      Upload all the file(s) needed to create your import file(s).
    </v-card-text>
    <v-container>
      <v-row v-for="reportInput in reportInputs" :key="reportInput.id">
        <v-col cols=2></v-col>
        <v-col cols=8>
          <FileUploadWithVideo :reportInput="reportInput" @reportChange="$emit('reportChange', reportInputs)" />
        </v-col>
        <v-col cols=2>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FileUploadWithVideo from './FileUploadWithVideo.vue'

export default {
  name: 'ReportsUploader',
  props: ['reports', 'disabled'],
  components: {
    FileUploadWithVideo
  },

  computed: {
    reportInputs() {
      let inputs = []

      if (this.reports) {
        inputs = this.reports.map(function (report) {
          return {
            id: report.id,
            label: report.display_name,
            value: null,
            name: report.name,
            videoSource: report.video_url,
            reportExplanation: report.report_explanation,
            reportInstructions: report.report_instructions,
            reportTips: report.report_tips,
            showVideo: false
          }
        })
      }

      return inputs
    }
  },
  methods: {
  }
}
</script>
