<template>
  <v-container>
    <v-row>
      <v-col cols=9>
        <v-file-input v-model="reportInput.value" :label="reportInput.label" prepend-icon="mdi-file-pdf"
          accept="application/pdf" @change="$emit('reportChange')"></v-file-input>
      </v-col>
      <v-col cols=3 class="d-flex justify-right align-center">
        <v-row>
          <v-col cols=4 class="d-flex justify-right align-center">
            <div v-if="reportInput.reportExplanation !== undefined">
              <button @click="showExplainModal" class="mr-10 mb-2"><img src="../../public/icons/doc_explain.png" class="logo"></button>
              <ReportInfoModal v-show="isExplainModalVisible" :reportInput="reportExplanationModalInfo" @close="closeExplainModal" />
            </div>
          </v-col>
          <v-col cols=4 class="d-flex justify-right align-center">
            <div v-if="reportInput.reportInstructions !== undefined">
              <button @click="showInstructionsModal" class="mr-10 mb-2"><img src="../../public/icons/doc_map.png" class="logo"></button>
              <ReportInfoModal v-show="isInstructionsModalVisible" :reportInput="reportInstructionsModalInfo" @close="closeInstructionsModal" />
            </div>
          </v-col>
          <v-col cols=4 class="d-flex justify-right align-center">
            <div v-if="reportInput.reportTips !== undefined">
              <button @click="showTipsModal" class="mr-10 mb-2"><img src="../../public/icons/doc_info.png" class="logo"></button>
              <ReportInfoModal v-show="isTipsModalVisible" :reportInput="reportTipsModalInfo" @close="closeTipsModal" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ReportInfoModal from './ReportInfoModal.vue'
export default {
  name: 'FileUploadWithVideo',
  components: {
    ReportInfoModal
  },
  props: ['reportInput'],
  data: () => ({
    showVideo: false,
    isExplainModalVisible: false,
    isInstructionsModalVisible: false,
    isTipsModalVisible: false
  }),
  computed: {
    reportExplanationModalInfo() {
        return {
          title: "Report Explanation",
          contents: this.reportInput.reportExplanation
        }
    },
    reportInstructionsModalInfo() {
        return {
          title: "Report Instructions",
          contents: this.reportInput.reportInstructions
        }
    },
    reportTipsModalInfo() {
        return {
          title: "Report Tips",
          contents: this.reportInput.reportTips
        }
    }
  },
  methods: {
    showExplainModal() {
        this.isExplainModalVisible = true;
      },
    closeExplainModal() {
        this.isExplainModalVisible = false;
    },
    showInstructionsModal() {
        this.isInstructionsModalVisible = true;
      },
    closeInstructionsModal() {
        this.isInstructionsModalVisible = false;
    },
    showTipsModal() {
        this.isTipsModalVisible = true;
      },
    closeTipsModal() {
        this.isTipsModalVisible = false;
    }
  }
}
</script>
